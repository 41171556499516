import React from "react"
import { ImageVideo } from "../../../ImageVideo/ImageVideo"
import { TextButtonsGrid } from "../../../TextButtonsGrid/TextButtonsGrid"
import { withImageGridCard } from "./withImageGridCard"

export const ImageGridCard = withImageGridCard(
  ({ ratio, image, content, additionalContent, handlePromoClick }) => (
    <div onClick={handlePromoClick}>
      {Boolean(content) && <TextButtonsGrid content={content} />}
      <ImageVideo imageVideo={image} ratio={ratio} />
      {Boolean(additionalContent) && (
        <TextButtonsGrid content={additionalContent} />
      )}
    </div>
  ),
)
