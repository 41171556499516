import React from "react"

import { withFeaturedContentCarousel } from "./withFeaturedContentCarousel"
import {
  Wrapper,
  SliderWrapper,
  Title,
  Content,
  ButtonWrapper,
} from "./FeaturedContentCarouselStyles"
import { TextButtonsGrid } from "../../TextButtonsGrid/TextButtonsGrid"
import { Slider, Slide } from "../../Slider/Slider"
import { Icon } from "../../Icon/Icon"
import { Next, Prev } from "../../Slider/SliderStyles"
import { ButtonLink } from "../../ButtonLink/ButtonLink"
import { HeroCard } from "../Hero/Card/HeroCard"
import { ImageGridCard } from "../ImageGrid/Card/ImageGridCard"

export const FeaturedContentCarousel = withFeaturedContentCarousel(
  ({
    title,
    button,
    images,
    content,
    layout,
    id,
    ratio,
    handlePromoClick,
  }): JSX.Element =>
    images.length > 0 ? (
      <Wrapper layout={layout}>
        <Title layout={layout}>{title}</Title>
        <Content>
          <TextButtonsGrid content={content} />
        </Content>
        <SliderWrapper>
          <Slider
            className={"product-carousel"}
            layout={
              layout === "normal" && typeof content !== "object"
                ? "product-carousel-4"
                : "product-carousel-3"
            }
            prevEl={`#id-${id}-carousel-prev`}
            nextEl={`#id-${id}-carousel-next`}
          >
            {images?.map(image => {
              if (image._type === "imageCardHero") {
                return (
                  <Slide key={image._key}>
                    <HeroCard
                      card={image}
                      ratio={ratio}
                      handlePromoClick={handlePromoClick}
                    />
                  </Slide>
                )
              }
              if (image._type === "imageCardNormal") {
                return (
                  <Slide key={image._key}>
                    <ImageGridCard
                      card={image}
                      ratio={ratio}
                      handlePromoClick={handlePromoClick}
                    />
                  </Slide>
                )
              }
            })}
          </Slider>
          <Prev id={`id-${id}-carousel-prev`}>
            <Icon name={"arrowLeft"} />
          </Prev>
          <Next id={`id-${id}-carousel-next`}>
            <Icon name={"arrow"} />
          </Next>
        </SliderWrapper>
        {button?.link && (
          <ButtonWrapper>
            <ButtonLink
              link={button?.link}
              style={button?.style}
              size={button?.size}
              wide
            />
          </ButtonWrapper>
        )}
      </Wrapper>
    ) : null,
)

export default FeaturedContentCarousel
