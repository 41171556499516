import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"

export const withFeaturedContentCarousel = Component => ({
  name = "FeaturedContentCarousel",
  title,
  button = undefined,
  content = undefined,
  images,
  ratio,
  layout = "featured",
  analytics = {},
  id = undefined,
  _key = undefined,
}) => {
  const {
    trackPromoImpression,
    VisibilitySensor,
    trackPromoClick,
  } = useAnalytics()

  Component.displayName = name
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <Component
        id={id || _key}
        title={title}
        button={button}
        images={images}
        content={content}
        layout={typeof content === "undefined" ? "normal" : layout}
        ratio={ratio}
        handlePromoClick={() => trackPromoClick({ ...analytics })}
      />
    </VisibilitySensor>
  )
}
