import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`relative`

export const Prev = styled.button`
  ${tw`w-2-8 h-2-8 md:h-6 flex items-center justify-center bg-white bg-opacity-50 border border-white border-opacity-50 rounded-full md:rounded absolute md:left-0 top-2/5 left-2 z-10`}
  &.swiper-button-disabled {
    ${tw`opacity-25`}
  }
  span {
    svg {
      ${tw`w-1-8 md:w-2-1 h-1-8 md:h-2-1`}
    }
  }
`

export const Next = styled.button`
  ${tw`w-2-8 h-2-8 md:h-6 flex items-center justify-center bg-white bg-opacity-50 border border-white border-opacity-50 rounded-full md:rounded absolute md:right-0 top-2/5 right-2 z-10`}
  &.swiper-button-disabled {
    ${tw`opacity-25`}
  }
  span {
    svg {
      ${tw`w-1-8 md:w-2-1 h-1-8 md:h-2-1`}
    }
  }
`
