import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`relative`}
  ${({ layout }) => (layout === "featured" ? tw`md:pl-33` : tw``)}
`

export const Title = styled.h3`
  ${tw`text-16 leading-1.25 md:text-25 md:leading-1.4 tracking-10 font-semibold text-center md:text-left uppercase mb-2-4 md:mb-4`}
  ${({ layout }) => (layout === "featured" ? tw`md:hidden` : tw``)}
`

export const Content = tw.div`
  hidden md:flex absolute inset-y-0 left-0 h-full max-w-30 items-center justify-center pb-2
`

export const SliderWrapper = tw.div`
  relative pl-2 md:pl-0
`

export const ButtonWrapper = tw.div`
  px-2 flex md:hidden justify-center mt-2
`
