import React from "react"

export const withImageGridCard = Component => ({
  name = "ImageGridCard",
  card,
  ratio,
  handlePromoClick,
}) => {
  const { image, content, additionalContent } = card || {}
  Component.displayName = name
  return (
    <Component
      ratio={ratio}
      image={image}
      content={content}
      additionalContent={additionalContent}
      handlePromoClick={handlePromoClick}
    />
  )
}
