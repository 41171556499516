import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { withSlider } from "./withSlider"

SwiperCore.use([Navigation])

export const Slider = withSlider(
  ({ navigation, slidesPerView, breakpoints, spaceBetween, children }) => (
    <Swiper
      navigation={navigation}
      slidesPerView={slidesPerView}
      breakpoints={breakpoints}
      spaceBetween={spaceBetween}
      lazy={true}
      preloadImages={false}
    >
      {children}
    </Swiper>
  ),
)

export const Slide = SwiperSlide
